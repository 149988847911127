import { getLodop } from "@/utils/LodopFuncs";
import JsBarcode from "jsbarcode";
import { T } from "@/locales";
import NP from "number-precision";

export default function printOrder(items, printConfig) {
  const LODOP = getLodop();
  LODOP.PRINT_INIT("PickingRecord");
  LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");
  LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

  for (const item of items) {
    console.log(item);
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, item.picking_order_number, {
      format: "CODE128",
      width: 2,
      height: 35,
      displayValue: true,
      background: "#ffffff",
      lineColor: "#000000",
    });
    const barcodeBase64 = canvas.toDataURL();
    // materialItems = sortTableData(printConfig.tableSortType, printConfig.tableSortField, item.picking_record_material_items);
    const materialItems = item.picking_record_material_items;

    const perPageRows = printConfig.tableRows ?? 10;
    const pageCount = Math.ceil(materialItems.length / perPageRows);
    for (let pageIndex = 1; pageIndex <= pageCount; pageIndex++) {
      // Header
      const headerFields = (printConfig.headerFields ?? []).map((fieldName) => {
        const fieldItem = getHeaderField(item, fieldName);
        if (fieldItem) {
          return `
            <div style="display: inline-block; width: 33.33%;">
              <div style="display: flex; justify-content: space-between; margin-top: 4px;">
                <div style="flex-grow: 0; padding: 0 8px; white-space: nowrap">${fieldItem.label}:</div>
                <div style="flex-grow: 1;">${fieldItem.value}</div>
              </div>
            </div>`;
        }
        return null;
      });

      // Table
      const pageMaterialItems = materialItems.slice((pageIndex - 1) * perPageRows, pageIndex * perPageRows);
      const tableHeader = (printConfig.tableFields ?? []).map((fieldName) => `<td>${getTablerFieldLabel(fieldName)}</td>`);
      const tableRows = pageMaterialItems.map((item) => {
        const tableCols = (printConfig.tableFields ?? []).map(
          (fieldName) => `<td>${getTablerFieldValue(item, fieldName)}</td>`
        );

        return `<tr>${tableCols.join("")}</tr>`;
      });

      const tableTotalCols = (printConfig.tableFields ?? [])
        .slice(1)
        .map((fieldName) => getTableTotalValue(materialItems, fieldName));

      const pageContent = `
        <div style="width: 210mm; height: 297mm; position: relative;">
          <div style="padding: 16px 16px 0 16px; height: 100%;">
            <div style="font-size: 36px; text-align: center;">
              <span>${printConfig.title ?? ""}</span>
            </div>

            <div style="margin-top: 8px; text-align: right;">
              <img src="${barcodeBase64}" />
            </div>

            <div style="margin-top: 8px; padding: 0 16px; display: flex; flex-wrap: wrap;">
              ${headerFields.filter((value) => value).join("")}
            </div>

            <div style="margin-top: 16px; font-size: 14px;">
              <table border="1" cellspacing="0" style="width: 100%; text-align: center;">
                <tr>${tableHeader.join("")}</tr>
                ${tableRows.join("")}
                <tr>
                  <td>合计:</td>
                  ${tableTotalCols.join("")}
                </tr>
              </table>
            </div>
          
            <div style="margin-top: ${printConfig.tableMarginBottom}px; padding: 0 16px; display: flex;">
              <div style="width: 50%; 
                text-align: ${printConfig.leftTextAlign ?? "left"};
                font-size: ${printConfig.leftTextFontSize ?? 12};
                font-weight: ${printConfig.leftTextFontWeight ? "bold" : "normal"};
                font-style: ${printConfig.leftTextFontItalic ? "italic" : "normal"};"
              >${printConfig.leftTextContent ?? ""}</div>
              <div style="width: 50%;
                text-align: ${printConfig.rightTextAlign ?? "left"};
                font-size: ${printConfig.rightTextFontSize ?? 12};
                font-weight: ${printConfig.rightTextFontWeight ? "bold" : "normal"};
                font-style: ${printConfig.rightTextFontItalic ? "italic" : "normal"};"
              >${printConfig.rightTextContent ?? ""}</div>
            </div>
            <div style="position: absolute;
              bottom: ${printConfig.bottomTextBottom + 24}px;
              left: 0; right: 0; padding: 0 16px;">
              <div style="width: 100%;
                text-align: ${printConfig.bottomTextAlign ?? "left"};
                font-size: ${printConfig.bottomTextFontSize ?? 12};
                font-weight: ${printConfig.bottomTextFontWeight ? "bold" : "normal"};
                font-style: ${printConfig.bottomTextFontItalic ? "italic" : "normal"};"
              >${printConfig.bottomTextContent ?? ""}</div>
            </div>

            <div style="position: absolute; bottom: 0; left: 0; right: 0;">
              <div style="width: 100%; text-align: center;">第 ${pageIndex} 页 / 共 ${pageCount} 页</div>
            </div>
          </div>  
        </div>`;

      LODOP.NewPage();
      LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
    }
  }

  LODOP.PREVIEW();
}

function getHeaderField(item, fieldName) {
  switch (fieldName) {
    case "picking_order_number":
      return { label: T("拣货单号"), value: item.picking_order_number ?? "" };
    case "warehouse":
      return { label: T("仓库"), value: item.warehouse_name ?? "" };
    case "client":
      return { label: T("货主"), value: item.client_name ?? "" };
    case "external_number":
      return { label: T("往来单位单号"), value: item.external_number ?? "" };
    case "creator":
      return { label: T("创建人"), value: item.creator_name ?? "" };
    case "create_time":
      return { label: T("创建时间"), value: item.create_time ?? "" };
    default:
      return null;
  }
}

function sortTableData(sortType, sortField, items) {
  items.sort(function (a, b) {
    if (a[sortField] < b[sortField]) {
      return sortType === "positive" ? -1 : 1;
    }
    if (a[sortField] > b[sortField]) {
      return sortType === "positive" ? 1 : -1;
    }
    return 0;
  });

  return items;
}

function getTablerFieldLabel(fieldName) {
  switch (fieldName) {
    case "material_number":
      return T("货品编号");
    case "material_name":
      return T("货品名称");
    case "batch_number":
      return T("拣货批次");
    case "location_number":
      return T("拣货库位");
    case "total_quantity":
      return T("拣货数量");
    default:
      return "";
  }
}

function getTablerFieldValue(item, fieldName) {
  switch (fieldName) {
    case "material_number":
      return item.material_number ?? "";
    case "material_name":
      return item.material_name ?? "";
    case "batch_number":
      return item.batch_number ?? "";
    case "location_number":
      return item.location_number ?? "";
    case "total_quantity":
      return item.total_quantity ?? "";
    default:
      return "";
  }
}

function getTableTotalValue(items, fieldName) {
  switch (fieldName) {
    case "total_quantity":
      return `<td>${items.reduce((accumulator, currentItem) => accumulator + currentItem.total_quantity ?? 0, 0)}</td>`;
    default:
      return "<td></td>";
  }
}
